import { useEffect, useState } from "react";

const Valuations = () => {
  return (
    <>
      <div className="bg-light p-5">
        <h1>Points valuations</h1>
        <p>You can configure multiplies for different points programs.</p>
        <div className="form-floating col-md-6 col-lg-4 mb-3">
          <input type="number" className="form-control" id="chaseRewards" />
          <label for="chaseRewards">Chase Rewards Points</label>
        </div>
        <div className="form-floating col-md-6 col-lg-4 mb-3">
          <input type="number" className="form-control" id="preferredRewards" />
          <label for="preferredRewards">BofA Preferred Rewards</label>
        </div>
        <div className="form-floating col-md-6 col-lg-4 mb-3">
          <input type="number" className="form-control" id="amexRewards" />
          <label for="amexRewards">Amex Membership Rewards</label>
        </div>
      </div>
    </>
  );
}

export default Valuations;
