import Header from "./Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cards from "./Cards";
import Home from "./Home";
import Valuations from "./Valuations";

const App = () => {
  return (
    <Router>
      <Header />
      <main className="container">
        <Switch>
          <Route path="/cards">
            <Cards />
          </Route>
          <Route path="/valuations">
            <Valuations />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
    </Router>
  );
};

export default App;
