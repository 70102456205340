import { useEffect, useState } from "react";

const database = [
  {
    name: 'Citi Double Cash',
    system: 'Mastercard',
    points: false,
    other: 2,
    categories: [],
    limit: 'None',
  },
  {
    name: 'Fidelity Rewards Visa',
    system: 'Visa',
    points: false,
    other: 2,
    categories: [],
    limit: 'None',
  },
  {
    name: 'PayPal Cashback Mastercard',
    system: 'Mastercard',
    points: false,
    other: 2,
    categories: [],
    limit: 'None',
  },
  {
    name: 'Bank of America Travel Rewards',
    system: 'Visa',
    points: true,
    other: 2.625,
    categories: [],
    limit: 'None',
  },
  {
    name: 'Barclays Uber',
    system: 'Visa',
    points: false,
    other: 1,
    categories: [
      {
        key: 'uber',
        back: 5,
      },
      {
        key: 'restaurants',
        back: 3,
      },
      {
        key: 'travel',
        back: 3,
      },
    ],
    limit: 'None',
  },
];

const categories = {
  all: 'All purchases',
  travel: 'Travel',
  restaurants: 'Restaurants',
  grocery: 'Grocery Stores',
  home: 'Home Improvements',
  gas: 'Gas Stations',
  uber: 'Uber',
};

const cashbackSign = card => card.points ? 'x' : '%';

const cashbackMax = (card, category) => {
  if (category !== 'none') {
    const match = card.categories.find(it => it.key === category);
    if (match) {
      return match.back;
    } else {
      return card.other;
    }
  } else {
    if (card.categories.length > 0) {
      return card.categories.map(it => it.back).reduce((a, b) => Math.max(a, b));
    } else {
      return card.other;
    }
  }
};

const categoryDisplay = (card, category) => {
  if (category !== 'none') {
    const match = card.categories.find(it => it.key === category);
    if (match) {
      return categories[category];
    } else {
      return 'All';
    }
  } else {
    if (card.categories.length > 1) {
      return 'Some';
    } else if (card.categories.length > 0) {
      return categories[card.categories[0].key];
    } else {
      return 'All';
    }
  }
};

const Cards = () => {
  const [category, setCategory] = useState('none');
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(database
      .map(it => ({
        name: it.name,
        min: it.other,
        max: cashbackMax(it, category),
        category: categoryDisplay(it, category),
        limit: it.limit,
        system: it.system,
        points: it.points,
      }))
      .filter(it => it.max >= 2)
      .sort((a, b) => b.max - a.max)
    );
  }, [category]);

  return (
    <>
      <div className="bg-light p-5">
        <div className="input-group mb-3">
          <label className="input-group-text">Filter</label>
          <select className="form-select" value={category} onChange={e => { setCategory(e.target.value) }}>
            <option value='none'>Any category</option>
            {['all', 'travel', 'restaurants', 'grocery', 'home', 'gas'].map(it =>
              <option value={it} key={it}>{categories[it]}</option>
            )}
          </select>
          <select className="form-select">
            <option>Any options</option>
          </select>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Card</th>
              <th scope="col">Min</th>
              <th scope="col">Max</th>
              <th scope="col">Category</th>
              <th scope="col">Limit</th>
              <th scope="col">System</th>
            </tr>
          </thead>
          <tbody>
            {cards.map(it =>
              <tr key={it.name}>
                <td>{it.name}</td>
                <td>{it.min}{cashbackSign(it)}</td>
                <td>{it.max}{cashbackSign(it)}</td>
                <td>{it.category}</td>
                <td>{it.limit}</td>
                <td>{it.system}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Cards;
