const Home = () => {
  return (
    <>
      <div className="bg-light p-5">
        <h1 className="pb-4">Allergic to Fees?</h1>
        <p className="lead pb-4">This website is about credit cards with good cashback and no annual fees. Unlike most other credit card websites, we will never shill you mediocre cards for referral commissions. In fact, we never use referral links to avoid any bias.</p>
        <a className="btn btn-lg btn-primary" href="/cards" role="button">View cards &raquo;</a>
      </div>
    </>
  );
}

export default Home;
